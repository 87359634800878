// This file can be replaced during build by using the `fileReplacements` array.

// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  adfs: {
    // issuer: 'https://certadfs16.cc.cnh.com/adfs',
    issuer: 'https://sso.cc.cnh.com/adfs',
    // redirectUri: 'https://directxfe.azurewebsites.net/Main',
    redirectUri: 'https://directx.cnhind.com/Main',
    //  clientId: 'b2935070-1a7e-4753-aa1f-c0bdf5dbce8e',
    clientId: '4c6a6666-660a-4dda-8b17-fd281aa13907',
    requestAccessToken: true,
    scope: 'openid',
    showDebugInformation: true,
    // resource: 'https://eudirectapimgmi01.azure-api.net/directxbe',
    resource: 'https://az-eu-fsdrx-apim-p-020.azure-api.net/directxbe',
    silentRefreshRedirectUri:
      'http://localhost:8081/mip/oauth/redirect?refresh=true',
    //  loginUrl: 'https://certadfs16.cc.cnh.com/adfs/oauth2/authorize',
    loginUrl: 'https://sso.cc.cnh.com/adfs/oauth2/authorize',
    //  tokenEndpoint: 'https://certadfs16.cc.cnh.com//adfs/oauth2/token',
    tokenEndpoint: 'https://sso.cc.cnh.com/adfs/oauth2/token',
    clearHashAfterLogin: false,
    useSilentRefresh: false,
    //  logoutUrl: 'https://certadfs16.cc.cnh.com/adfs/oauth2/logout?wa=wsignout1.0&RedirectURL_VMS=' + window.location.origin,
    logoutUrl:
      'https://sso.cc.cnh.com/adfs/oauth2/logout?wa=wsignout1.0&RedirectURL_VMS=' +
      window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    disableAtHashCheck: true,
    sessionChecksEnabled: true,
    sessionCheckIntervall: 3000,
  },
  b2c: {
    issuer:
      'https://authiveco.b2clogin.com/003847a7-920b-41fd-8065-9ad27cbc5d87/v2.0/',
    issuerLogout:
      'https://authiveco.b2clogin.com/003847a7-920b-41fd-8065-9ad27cbc5d87/oauth2/v2.0/logout?p=b2c_1a_signin_directx_v3&post_logout_redirect_uri=https%3A%2F%2Fdirectx.iccapital.com%2FauthFlow&id_token_hint=',
    policy: 'B2C_1A_SIGNIN_DIRECTX_V3',

    redirectUri: window.location.origin + '/authFlow',
    clientId: 'bf94a667-5425-4db5-9ebb-f76c416eeca0',
    responseType: 'code',
    strictDiscoveryDocumentValidation: false,
    scope: 'openid offline_access',
    showDebugInformation: true,
    customQueryParams: null,
  },
  b2c_pr: {
    issuer:
      'https://authiveco.b2clogin.com/003847a7-920b-41fd-8065-9ad27cbc5d87/v2.0/',
    redirectUri: window.location.origin + '/PreregistrationFlow',
    clientId: 'bf94a667-5425-4db5-9ebb-f76c416eeca0',
    responseType: 'code',
    strictDiscoveryDocumentValidation: false,
    scope: 'openid',
    showDebugInformation: true,
    customQueryParams: null,
  },
  httpConfig: {
    http: 'http://',

    https: 'https://',

    baseUrl: 'eumipdapi010.azure-api.net/',

    baseRetries: 3,

    defaultDelay: 60000,

    baseHeaders: {
      contentType: 'application/json',

      authorization: '',
    },

    apiManagerSubscKey: '95077cf303894bfda2746a1b0b93b80e', // 07fbac8142fb4375bbb86885d8ca7e2b

    aclProduct: 'aclproduct',

    dashboardManagementProduct: 'dashmgtproduct',

    widgetManagementProduct: 'wdgmgtproduct',
  },

  changePwdConfig: {
    changePwdRedirectUri:
      window.location.origin + '/adfs/portal/updatepassword',

    // changePwdRedirectUri: 'https://myiveco.azurewebsites.net' + '/adfs/portal/updatepassword'
  },
  // Da qui in poi è sufficiente aggiungere in modo coerente in entrambi gli oggetti il nuovo creditor
  merchants: {
    ivecoespana: 'DE5636700002197951', // CR000066DTQR69
    cics: 'DE5636500002197951', // CR000066WRM11R
    ivecofinland: 'DE5638900002197951',
    ivecofranca: 'DE5640600002197951',
    ivecomediterranea: 'DE5638800002197951',
    ivecouklimited: 'DE5646500002197951',
    ivecoukcapitallimited: 'DE5646600002197951',
    ivecoestsas: 'DE5649300002197951',
    socdifvehindsdvisas: 'DE5649300002197951',
    ivecolvisas: 'DE5649300002197951',
    iveconordsas: 'DE5649300002197951',
    ivecoprovencesas: 'DE5649300002197951',
    ivecofrancesa: 'DE5669800002197951',
    ivecomagirusag: 'CR000068B6YMG8',
  },
  creditors: [
    {
      creditorid: 'DE5636700002197951',
      name: 'IVECO ESPANA S.L.',
      address: 'AVENIDA ARAGON 402',
      city: 'MADRID',
      zip: '28022',
      country: 'ES',
    },
    {
      creditorid: 'DE5636500002197951',
      name: 'IVECO Capital Solutions SpA',
      address: 'Via Puglia 35',
      city: 'Torino',
      zip: ' 10123',
      country: 'IT',
    },
    {
      creditorid: 'DE5638900002197951',
      name: 'IVECO FINLAND OY',
      address: 'Kiilaniityntie 10',
      city: 'ESPOO',
      zip: ' 2920',
      country: 'FI',
    },
    {
      creditorid: 'DE5640600002197951',
      name: 'ZONA F. ALARI SEPAUTO SA',
      address: 'AVENIDA SANT JULIA 148',
      city: 'GRANOLLERS',
      zip: ' 8403',
      country: 'ES',
    },
    {
      creditorid: 'DE5638800002197951',
      name: 'MEDITERRANEA DE CAMIONES S.L.',
      address: 'AVENIDA ITALIA 4',
      city: 'COSLADA',
      zip: '28821',
      country: 'ES',
    },
    {
      creditorid: 'DE5646500002197951',
      name: 'IVECO LIMITED',
      address: 'Cranes Farm Road',
      city: 'BASILDON',
      zip: 'SS14 3AD',
      country: 'GB',
    },
    {
      creditorid: 'DE5646600002197951',
      name: 'CNH Industrial Capital Limited',
      address: 'Cranes Farm Road',
      city: 'BASILDON',
      zip: 'SS14 3AD',
      country: 'GB',
    },
    {
      creditorid: 'DE5649700002197951',
      name: 'IVECO DANMARK A/S',
      address: 'Roholmsvej 19',
      city: 'Albertslund',
      zip: '2620',
      country: 'DK',
    },
    {
      creditorid: 'DE5638900002197951',
      name: 'IVECO NEDERLAND B.V.',
      address: 'Wanraaij 9 - Bus 3',
      city: 'Andelst',
      zip: '6673 DM',
      country: 'NL',
    },
    {
      creditorid: 'DE5638900002197951',
      name: 'IVECO BELGIUM N.V.',
      address: 'Alfons Gossetlaan 28A',
      city: 'Groot-Bijgaarden',
      zip: '1702',
      country: 'BE',
    },
    {
      creditorid: 'DE5638900002197951',
      name: 'IVECO EST SAS',
      address: 'Zone Industrielle',
      city: 'Hauconcourt',
      zip: '57280',
      country: 'FR',
    },
    {
      creditorid: 'DE5638900002197951',
      name: 'SOCIETE DE DIFFUSION DE VEHICULES INDUSTRIELS SAS',
      address: '1 Rue della Garanne',
      city: 'Orvault',
      zip: '44700',
      country: 'FR',
    },
    {
      creditorid: 'DE5638900002197951',
      name: 'IVECO L.V.I. S.A.S.',
      address: '56 à 62 Route de Grenoble',
      city: 'Saint-Priest',
      zip: '69800',
      country: 'FR',
    },
    {
      creditorid: 'DE5638900002197951',
      name: 'IVECO NORD SAS',
      address: '2 rue de Gamand Centre Régional des Transports',
      city: 'Lesquin',
      zip: '59810',
      country: 'FR',
    },
    {
      creditorid: 'DE5638900002197951',
      name: 'IVECO PROVENCE S.A.S.',
      address: 'Zone Industrielle les Estroublans 18, Avenue de Rome',
      city: 'Vitrolles',
      zip: '13127',
      country: 'FR',
    },
    {
      creditorid: 'DE5669800002197951',
      name: 'IVECO FRANCE SAS',
      address: 'I Rue des Combats du 24 Aofit 1944 porteB',
      city: 'Venissieux',
      zip: '69200',
      country: 'FR',
    },
  ],

  ivecoOnUrl: 'https://www.iveco-on.com/login',
};

/*

* For easier debugging in development mode, you can import the following file

* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.

*

* This import should be commented out in production mode because it will have a negative impact

* on performance if an error is thrown.

*/

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
